import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaV3Module } from "ng-recaptcha";
import { NgSelectModule } from '@ng-select/ng-select';
import { MobiPrintErrorsComponent } from '@shared/components/mobi-print-errors/mobi-print-errors.component';
import { environment } from 'src/environments/environment';
import { SharedModule } from '@shared/shared.module';
@NgModule({
  declarations: [
    ResetPasswordComponent,
    MobiPrintErrorsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    NgSelectModule,
    SharedModule,
    RecaptchaFormsModule
  ],
  providers: [{provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey}],
})
export class ResetPasswordModule {
}
